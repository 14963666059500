import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ded9f1a = () => interopDefault(import('../pages/country.vue' /* webpackChunkName: "pages/country" */))
const _0b17cd48 = () => interopDefault(import('../pages/heartbeat/index.vue' /* webpackChunkName: "pages/heartbeat/index" */))
const _4553ea90 = () => interopDefault(import('../pages/hongkong.vue' /* webpackChunkName: "pages/hongkong" */))
const _3dcad674 = () => interopDefault(import('../pages/maintanance.vue' /* webpackChunkName: "pages/maintanance" */))
const _84061a62 = () => interopDefault(import('../pages/notsupport.vue' /* webpackChunkName: "pages/notsupport" */))
const _5956e00c = () => interopDefault(import('../pages/thailand.vue' /* webpackChunkName: "pages/thailand" */))
const _4268de22 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _02083bcd = () => interopDefault(import('../pages/_country/index.vue' /* webpackChunkName: "pages/_country/index" */))
const _f65584ec = () => interopDefault(import('../pages/_country/confirmation.vue' /* webpackChunkName: "pages/_country/confirmation" */))
const _75bdccfc = () => interopDefault(import('../pages/_country/default.vue' /* webpackChunkName: "pages/_country/default" */))
const _06a1d364 = () => interopDefault(import('../pages/_country/notfound.vue' /* webpackChunkName: "pages/_country/notfound" */))
const _3a7eb12c = () => interopDefault(import('../pages/_country/recovery.vue' /* webpackChunkName: "pages/_country/recovery" */))
const _b7db5e26 = () => interopDefault(import('../pages/_country/review.vue' /* webpackChunkName: "pages/_country/review" */))
const _a7f40bfc = () => interopDefault(import('../pages/_country/terms.vue' /* webpackChunkName: "pages/_country/terms" */))
const _50beb048 = () => interopDefault(import('../pages/_country/unsuccessful.vue' /* webpackChunkName: "pages/_country/unsuccessful" */))
const _6653c3fb = () => interopDefault(import('../pages/_country/events/reschedule.vue' /* webpackChunkName: "pages/_country/events/reschedule" */))
const _12149baf = () => interopDefault(import('../pages/_country/campaigns/_campaign_id/index.vue' /* webpackChunkName: "pages/_country/campaigns/_campaign_id/index" */))
const _081b85a1 = () => interopDefault(import('../pages/_country/events/_event_id/index.vue' /* webpackChunkName: "pages/_country/events/_event_id/index" */))
const _5e33e0f8 = () => interopDefault(import('../pages/_country/events/_event_id/changebooking.vue' /* webpackChunkName: "pages/_country/events/_event_id/changebooking" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/country",
    component: _1ded9f1a,
    name: "country"
  }, {
    path: "/heartbeat",
    component: _0b17cd48,
    name: "heartbeat"
  }, {
    path: "/hongkong",
    component: _4553ea90,
    name: "hongkong"
  }, {
    path: "/maintanance",
    component: _3dcad674,
    name: "maintanance"
  }, {
    path: "/notsupport",
    component: _84061a62,
    name: "notsupport"
  }, {
    path: "/thailand",
    component: _5956e00c,
    name: "thailand"
  }, {
    path: "/",
    component: _4268de22,
    name: "index"
  }, {
    path: "/:country",
    component: _02083bcd,
    name: "country"
  }, {
    path: "/:country/confirmation",
    component: _f65584ec,
    name: "country-confirmation"
  }, {
    path: "/:country/default",
    component: _75bdccfc,
    name: "country-default"
  }, {
    path: "/:country/notfound",
    component: _06a1d364,
    name: "country-notfound"
  }, {
    path: "/:country/recovery",
    component: _3a7eb12c,
    name: "country-recovery"
  }, {
    path: "/:country/review",
    component: _b7db5e26,
    name: "country-review"
  }, {
    path: "/:country/terms",
    component: _a7f40bfc,
    name: "country-terms"
  }, {
    path: "/:country/unsuccessful",
    component: _50beb048,
    name: "country-unsuccessful"
  }, {
    path: "/:country/events/reschedule",
    component: _6653c3fb,
    name: "country-events-reschedule"
  }, {
    path: "/:country/campaigns/:campaign_id",
    component: _12149baf,
    name: "country-campaigns-campaign_id"
  }, {
    path: "/:country/events/:event_id",
    component: _081b85a1,
    name: "country-events-event_id"
  }, {
    path: "/:country/events/:event_id?/changebooking",
    component: _5e33e0f8,
    name: "country-events-event_id-changebooking"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
