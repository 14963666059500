export const state = () => ({
    couponItems: [],
    eventItems: null,
    currentDate: '',
    countryCode: '',
    lang: '',
    merchantSettings: '',
    lang_str: '',
    adyenConfig: null,
    adyenAction: null,
    token: '',
    merchant_id: '',
    event_modifiers: [],
    event_selectedDate: '',
    event_selectedSlots: [],
    event_customMessage: '',
    event_subTotal: 0,
    commit_called: false,
    customerDetails: null,
    reference: '',
    charge_id: '',
    notfound: false,
    payment_info: '',
    select_lang: '',
    timerTime: '',
    EndTime: '',
    countdownflag: false,
    CampaignCartList:[],
    limit:25,
    omise_transaction_id:'',
    utm_value:'',
})

export const mutations = {
    assignUtmValue: function (state,value){
        state.utm_value = value;
    },
    clearCart: function (state,value) { 
        state.CampaignCartList = []
    },
    omiseTransactionId: function (state,value){
        state.omise_transaction_id = value;
    },
    assignLimit: function (state,value){
        state.limit = value;
    },
    assignCampaignCart: function(state,value) {
        var find = state.CampaignCartList.findIndex(p => p.campaign_code === value.campaign_code);
        console.log('find',find);
        if (find === -1) {
            console.log('assignCampaignCart if');
            state.CampaignCartList.push(value);
            
        } else {
            console.log('assignCampaignCart else');
            if (Number(state.CampaignCartList[find].selectedQuantity) === 0) {
                state.CampaignCartList[find].selectedQuantity = 0;
                state.CampaignCartList.splice(find, 1);
            } else {
                state.CampaignCartList[find].selectedQuantity = value.selectedQuantity
                state.CampaignCartList[find].selling_price = value.selling_price
                state.CampaignCartList[find].total_amount = value.total_amount
            }
        }
        
        // state.CampaignCartList.push(value);
    },
    assignStartTimer: function(state, value) {
        state.timerTime = value;
    },
    assignEndTimer: function(state, value) {
        state.EndTime = value;
    },
    assignCountDownFlag: function(state, value) {
        console.log("state:", state, "value", value);
        state.countdownflag = value;
    },
    assignuserDetails: function(state, value) {
        state.customerDetails = value;
    },
    clearEvents: function(state, value) {
        state.eventItems = null;
        state.event_modifiers = [];
        state.event_selectedDate = '';
        state.event_selectedSlots = [];
        state.event_customMessage = '';
        state.commit_called = false;
    },
    assignCommit: function(state, value) {
        state.commit_called = true;
    },
    omiseCharge: function(state, value) {
        state.charge_id = value;
    },
    assignNotfound: function(state, value) {
        state.notfound = value;
    },
    assignDate: function(state, value) {
        state.currentDate = value;
    },
    assignreference: function(state, value) {
        state.reference = value;
    },
    assignSelectLang: function(state, value) {
        state.select_lang = value;
    },
    assignPaymentInfo: function(state, value) {
        state.payment_info = value;
    },

    assignEventSubTotal: function(state, value) {
        state.event_subTotal = value;
    },
    assignEventDate: function(state, value) {
        state.event_selectedDate = value
    },
    assignEventSlots: function(state, value) {
        console.log("Assign slots ", value);
        state.event_selectedSlots = value
    },
    assignCustomMessage: function(state, value) {
        state.event_customMessage = value
    },
    assignToken: function(state, value) {
        console.log("Session #### ", typeof value)
        state.token = value;
    },
    assignMerchant: function(state, value) {
        state.merchant_id = value;
    },
    assignLang: function(state, value) {
        console.log("Session #### ", typeof value)
        state.lang = value;
    },
    clearLang: function(state, value) {
        state.lang = '';
    },
    assignConfig: function(state, value) {
        state.adyenConfig = value;
    },
    assignAction: function(state, value) {
        state.adyenAction = value;
    },
    assignLangStr: function(state, value) {
        state.lang_str = value;
    },
    assignCountry: function(state, value) {

        state.countryCode = value;
    },
    assignMerchantSetting: function(state, value) {

        state.merchantSettings = value;
    },
    assignEventItem: function(state, value) {
        console.log("assignEventItem ", value);
        if (value.price_type === 'T') {
            for (var i in value.campaign_tiers) {
                console.log('value.selectedQuantity', value.selectedQuantity);
                console.log(value.selectedQuantity, '>=', value.campaign_tiers[i].campaign_tier.start_qty, '&&', value.selectedQuantity, '<=', value.campaign_tiers[i].campaign_tier.end_qty);
                if (value.selectedQuantity >= value.campaign_tiers[i].campaign_tier.start_qty && value.selectedQuantity <= value.campaign_tiers[i].campaign_tier.end_qty) {
                    value.selling_price = Number(value.campaign_tiers[i].campaign_tier.selling_price_tier)
                }
            }
            state.eventItems = value;
        } else {
            state.eventItems = value;
        }

    },
    assignTotal: function(state, value) {
        state.eventItems.total_amount = value
    },
    assignEvent: function(state, value) {
        console.log("Assign Event ", state.eventItems);
        if ((state.eventItems.campaign_limit > value.selectedQuantity) || state.eventItems.campaign_availability_limit !== 'A') {
            state.eventItems.selectedQuantity = Number(value.selectedQuantity) + 1;
        }
        var data = state.eventItems;
        if (data.price_type === 'T') {
            for (var i in data.campaign_tiers) {
                console.log('data.selectedQuantity', data.selectedQuantity);
                console.log(data.selectedQuantity, '>=', data.campaign_tiers[i].campaign_tier.start_qty, '&&', data.selectedQuantity, '<=', data.campaign_tiers[i].campaign_tier.end_qty);
                if (data.selectedQuantity >= data.campaign_tiers[i].campaign_tier.start_qty && data.selectedQuantity <= data.campaign_tiers[i].campaign_tier.end_qty) {
                    state.eventItems.selling_price = Number(data.campaign_tiers[i].campaign_tier.selling_price_tier)
                }
            }
        }
        console.log('assignEvent.eventItems',state.eventItems);
    },
    removeEvent: function(state, value) {
        if (state.eventItems.selectedQuantity > 0) {
            state.eventItems.selectedQuantity = Number(value.selectedQuantity) - 1;
        }
        var data = state.eventItems;
        if (data.price_type === 'T') {
            for (var i in data.campaign_tiers) {
                console.log('data.selectedQuantity', data.selectedQuantity);
                console.log(data.selectedQuantity, '>=', data.campaign_tiers[i].campaign_tier.start_qty, '&&', data.selectedQuantity, '<=', data.campaign_tiers[i].campaign_tier.end_qty);
                if (data.selectedQuantity >= data.campaign_tiers[i].campaign_tier.start_qty && data.selectedQuantity <= data.campaign_tiers[i].campaign_tier.end_qty) {
                    state.eventItems.selling_price = Number(data.campaign_tiers[i].campaign_tier.selling_price_tier)
                }
            }
        }
        console.log('removeEvent.eventItems',state.eventItems);
    },
    assign: function(state, value) {
        // console.log("coupon add session code ", value);
        // state.couponItems = [];
        var find = state.couponItems.findIndex(p => p.coupon_id === value.coupon_id);
        if (find === -1) {
            value['selectedQuantity'] = 1;
            state.couponItems.push(value);
        } else {
            if (state.couponItems[find].selectedQuantity < value.available_packs)
                state.couponItems[find].selectedQuantity = Number(state.couponItems[find].selectedQuantity) + 1;
        }
    },
    clear: function(state, value) {
        state.couponItems = [];
        // state.couponItems.push(value);
    },
    clearTimeout: function(state, value) {
        state.couponItems.length = 0;
        state.timerTime = '';
        state.EndTime = '';
        // state.couponItems.push(value);
    },
    remove: function(state, value) {
        var find = state.couponItems.findIndex(c => c.coupon_id === value.coupon_id);
        if (find != -1 && Number(state.couponItems[find].selectedQuantity) > 0) {
            state.couponItems[find].selectedQuantity = Number(state.couponItems[find].selectedQuantity) - 1;
            console.log(find, state.couponItems[find].selectedQuantity)
            if (Number(state.couponItems[find].selectedQuantity) === 0) {
                state.couponItems[find].selectedQuantity = 0;
                state.couponItems.splice(find, 1);
            }
        } else {
            console.log("Else ", find)
        }

    },
    assignEventModifier: function(state, value) {
        state.event_modifiers = value;
    },
    removeEventModifierCount: function(state, value) {
        var find = state.event_modifiers.findIndex(c => c.campaign_modifier.campaign_modifier.campaign_modifier_id === value.campaign_modifier.campaign_modifier.campaign_modifier_id);
        if (find != -1 && Number(state.event_modifiers[find].selectedCount) > value.campaign_modifier.campaign_modifier.min_qty) {
            if (state.event_modifiers[find].selectedCount >= value.campaign_modifier.campaign_modifier.min_qty) {
                state.event_modifiers[find].selectedCount = Number(state.event_modifiers[find].selectedCount) - 1;
            }
            // console.log(find, state.event_modifiers[find].selectedCount)
            if (Number(state.event_modifiers[find].selectedCount) === 0) {
                state.event_modifiers[find].selectedCount = 0;
                // state.event_modifiers.splice(find, 1);
            }
        } else {
            console.log("Else ", find)
        }

    },
    assignEventModifierCount: function(state, value) {
        var find = state.event_modifiers.findIndex(c => c.campaign_modifier.campaign_modifier.campaign_modifier_id === value.campaign_modifier.campaign_modifier.campaign_modifier_id);
        console.log("assignEventModifierCount ", find);
        if (find === -1) {
            value['selectedCount'] = value.campaign_modifier.campaign_modifier.min_qty;
            state.event_modifiers.push(value);
        } else {

            if (state.event_modifiers[find].selectedCount < value.campaign_modifier.campaign_modifier.max_qty)
                state.event_modifiers[find].selectedCount = Number(value.selectedCount) + 1;
        }
        console.log("assignEventModifierCount ", value);
    },
    addrange: function(state, value) {
        state.couponItems[value.index].quantity = value.value;
    }
}