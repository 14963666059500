export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppHeader1 = () => import('../../components/AppHeader1.vue' /* webpackChunkName: "components/app-header1" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderReview = () => import('../../components/AppHeaderReview.vue' /* webpackChunkName: "components/app-header-review" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderm1 = () => import('../../components/AppHeaderm1.vue' /* webpackChunkName: "components/app-headerm1" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderm2 = () => import('../../components/AppHeaderm2.vue' /* webpackChunkName: "components/app-headerm2" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HomeBanner = () => import('../../components/HomeBanner.vue' /* webpackChunkName: "components/home-banner" */).then(c => wrapFunctional(c.default || c))
export const Portfolio = () => import('../../components/Portfolio.vue' /* webpackChunkName: "components/portfolio" */).then(c => wrapFunctional(c.default || c))
export const ReviewBanner = () => import('../../components/ReviewBanner.vue' /* webpackChunkName: "components/review-banner" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
