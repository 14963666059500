
export default {
    asyncData({params}) {
        return {
            country: params.country
        }
    },
    data: function() {
        return {
            countryCode: this.$store.state.sessionStorage.countryCode,
            routename: this.$nuxt.$route.name
        }
    },
    head() {
    return {
      link: [
    //       { rel: 'icon', type: 'image/x-icon', href: `${this.merchantDetails.merchant_logourl}` },
    //       { rel: "stylesheet", href: "/css/style.css" },
          { rel: "stylesheet", href: "/css/bootstrap.min.css" },
          { rel: "stylesheet", href: "/css/bootstrapstyle.css" }
      ],
      script: [
        // {src: 'https://developers.kakao.com/sdk/js/kakao.js', body: true},
        // {src: 'https://www.hyatt.com/hds/bookends/1.latest/ecommerce/en-US/bookends.min.js', body: true},
        //{src: 'https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-606e8a1a85aa8f13', body: true},
        { src: "/js/jquery.min.js", body: true },
        { src: "/js/bootstrap.bundle.min.js", body: true },
      ],
      __dangerouslyDisableSanitizers: ['script', 'noscript', 'innerHTML'],
    }
   },
    methods: {
        changeLang: function(lang) {
            this.$i18n.locale = lang;
            window.location.reload();
        },
        header(){
        var dropdown = document.querySelectorAll('.dropdown');
        var dropdownArray = Array.prototype.slice.call(dropdown,0);
        dropdownArray.forEach(function(el){
          var button = el.querySelector('a[data-toggle="dropdown"]'),
              menu = el.querySelector('.dropdown-menu'),
              arrow = button.querySelector('i.icon-arrow');

          button.onclick = function(event) {
            if(!menu.hasClass('show')) {
              menu.classList.add('show');
              menu.classList.remove('hide');
              arrow.classList.add('open');
              arrow.classList.remove('close');
              event.preventDefault();
            }
            else {
              menu.classList.remove('show');
              menu.classList.add('hide');
              arrow.classList.remove('open');
              arrow.classList.add('close');
              event.preventDefault();
            }
          };
        })

        Element.prototype.hasClass = function(className) {
            return this.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(this.className);
        };

        },
        language(){
          $(document).ready(function(){
          // $(".lang-button").click(function(){
            $(".navbar-nav1").css({"display": "none"});
            $(".navbar-nav2").css({"display": "flex"});
            $(".back-btn").css({"display": "flex"});
            $("#close-btn").css({"display": "none"});
             
          // });
        });
        },
        backbtn(){
          $(document).ready(function(){
          // $(".back-btn").click(function(){
            $(".navbar-nav1").css({"display": "flex"});
            $(".navbar-nav2").css({"display": "none"});
            $(".back-btn").css({"display": "none"});
            $("#close-btn").css({"display": "flex"});

          // });
        });
        },
        goback: function() {
            // const params = new URLSearchParams(window.location.search)
            // window.history.back(-1);
            // this.$nuxt.$router.push({path: '/'+this.country})
            window.location.replace('/'+this.countryCode)
        },
        openNav() {
          $("#mySidebar").css({"width": "320px"});
            $("#main").css({"marginLeft": "250px"});
          // document.getElementById("mySidebar").style.width = "250px";
          // document.getElementById("main").style.marginLeft = "250px";
        },
         closeNav() {
           $("#mySidebar").css({"width": "0"});
            $("#main").css({"marginLeft": "0"});
          // document.getElementById("mySidebar").style.width = "0";
          // document.getElementById("main").style.marginLeft= "0";
        }
    },
    mounted: function() {
        console.log("this.$nuxt.$route.name ", this.$nuxt.$route)
        this.header();
        // this.subheader();
        
        $(".loyal-btn").click(function(){
        $(".nav-ul").toggle();
    });
    }
}
