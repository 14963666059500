export default function({ $axios, redirect, error: nuxtError }) {
    $axios.defaults.timeout = 7200000;

    $axios.onError(error => {
        // console.log("$axios Error ", error);
        if (error.response.status !== 404 && error.response.status !== 401) {
            console.log("$axios Error ", error.response);
            if(error.response.data.error_type === 'omise'){
                nuxtError({
                    statusCode: error.response.status,
                    message: error.response.data.message,
                    error_type:error.response.data.error_type
                });
            }else{
                nuxtError({
                    statusCode: error.response.status,
                    message: error.message,
                });

            }
            
            return Promise.reject(error.response);
        } else {
            console.log("$axios Error ", error.response.status);
            return Promise.resolve(false);
        }

    })
}